.navbar{
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  background-color: var(--950);
  height: 60px;
  margin: 0;
  width: 100%;
  align-items: center;
}

.navitems{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  list-style: none;
  width: calc(100% - 70%);
  margin: 10px;
  padding: 10px;
  color: var(--500);
  font-weight: 900;
  font-size: 1.2rem;
  box-sizing: border-box; 
}

.navitems a{
    text-decoration: none;
    color: var(--500);
    font-size: 1.2rem;
}

.navitems a:hover{
    color: var(--950);
    background-color: var(--400);
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 20px;
}

.searchbox{
    border-color: var(--500);
    color: var(--500);
    border-radius: 50px;
    background-color: blur;
    border-width: 1.5px;
    /* margin: 20px; */
}

.navbar img {
    align-items: center;
    /* margin: 10px; */
}

.search-switch{
    display: flex;
    justify-content: space-between;
    margin-right: 15px;
}
  
.searchicon {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}

  .search-results {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 5%;
    width: 30vw;
    max-height: 300px;
    background-color: var(--950);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 10;
    cursor: pointer;
  }
  
  .search-result {
    display: flex;
    flex: 1;
    align-items: center;
    /* padding: 10px; */
    border-bottom: 0.75px solid #ddd;
  }
  
  .search-result-poster {
    width: 45px;
    height: 60px;
    margin-right: 10px;
  }
  
  /* .search-result-info {
    flex: 1;
  } */
  
  .search-result-title {
    margin-bottom: 5px;
    margin-top: 0px;
    font-size: 1rem;
    font-weight: bold;
    color: var(--400);
  }
  
  .search-result-year {
    margin: 0;
    font-size: .75rem;
    color: #e6e6e6;
  }