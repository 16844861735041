/* .actor-details {
    display: flex;
    flex-direction: column;
    background-color: var(--950);
    background-position: center;
    background-repeat: no-repeat;
    align-items: flex-end;
    justify-content: space-evenly;
} */

.actor-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
    margin: 20px;
    margin-top: 100px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.473);
    background-blend-mode: overlay;
}

.mainactor{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 50px;
    margin-left: 50px;
    margin-bottom: 150px;
}

.actorinfo{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-left: 50px;
    margin-right: 30px;

}

.actorinfo h1{
    font-size: 3rem;
    font-weight: bolder;
    margin-bottom: -30px;
}

.actorinfo button{
    background-color: var(--400);
    color: var(--950);
    font-weight: bold;
    padding: 10px;
    margin-top: 20px;
    border-radius: 50px;
}

.actorinfo p{
    font-size: 1rem;
}
.myactorimage{
    width: 200px;
    height: 300px;
    border-radius: 15px;
}