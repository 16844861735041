.movie-card {
    position: relative;
    width: 250px;
    height: 380px; /* Set card height to accommodate content */
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.2s ease;
  
    &:hover {
      transform: translateY(-5px);
    }
}
  
.movie-poster {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s ease;
}
  
.movie-card:hover .movie-poster {
    transform: scale(1.1);
}
  
.movie-info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    padding: 10px;
    box-sizing: border-box;
    color: #fff;
    transform: translateY(100%);
    transition: transform 0.2s ease;
}
  
.movie-card:hover .movie-info {
    transform: translateY(0);
}
  
.movie-title {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
}
  
.movie-release-date,
.movie-runtime,
.movie-genres,
.movie-rating {
    margin-bottom: 5px;
    font-size: 12px;
}
  
.movie-rating {
    font-weight: bold;
}
