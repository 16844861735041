.footer {
    background-color: var(--950);
    color: white;
    text-align: center;
    padding: 0px 0;
}
  
.footer-container {
    /* max-width: 1200px; */
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 20px;
}
  
.footer-logo img {
    width: 150px;
    margin-bottom: 0px;
}
  
.footer-links {
    display: flex;
    flex: 2;
    justify-content: space-around;
    margin-bottom: 20px;
}
  
.footer-section h4 {
    color: var(--400);
    margin-bottom: 10px;
}
  
.footer-section ul {
    list-style: none;
    padding: 0;
}
  
.footer-section ul li {
    margin-bottom: 8px;
}
  
.footer-section ul li a {
    color: white;
    text-decoration: none;
}
  
.footer-social {
    text-align: right;
    margin-bottom: 20px;
}
  
.footer-social h4 {
    color: var(--400);
    margin-bottom: 10px;
}
  
.social-icons {
    display: flex;
    justify-content: flex-end;
}
  
.social-icon {
    color:var(--400);
    text-decoration: none;
    margin: 0 10px;
    font-size: 20px;
}
  
.footer-bottom {
    border-top: 1px solid #333;
    padding-top: 10px;
    margin-top: 20px;
}
  