
.mainCard {
  display: flex;
  flex-direction: row;
  background-color: #0000009c;
  background-blend-mode: overlay;
  justify-content: space-around;
}

.poster {
  display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 50px;
    margin-left: 50px;
  border-radius: 10px;
}

.maininfo {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  margin-top: 50px;
  margin-left: 50px;
  align-items: flex-start;
}

.mainCardContent {
  margin-left: 50px;
}

.moviesName {
  font-size: 3rem;
  font-weight: bolder;
  /* margin-left: 50px; */
  margin-bottom: -10px;
}

button.movieGenres {
  background-color: var(--400);
  color: var(--950);
  font-weight: bold;
}

.movieTagline {
  font-size: 1rem;
  /* margin-left: 50px; */
  /* padding-bottom: 100px; */
}

.genre-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 20px;
}

.mainCardContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mainInline {
  display: flex;
  flex-direction: column;
}

.mainInline2 {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  /* padding-right: -160px; */
}

.rateButton {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}

.mainInline2 button {
  background-color: var(--500);
  padding-bottom: 7px;
  padding-top: 7px;
  color: white;
  font-weight: 900;
  font-size: 1.1rem;
  border-width: 1px solid white;
  border-radius: 5px;
}

.trailer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 50px;
}

.credits {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #5050507e;
}

.header {
  border-left: 3px solid var(--500);
  padding-left: 10px;
}

.actors {
  display: flex;
  flex-direction: row;
  gap: 0px;
  margin-top: 20px;
}

.actors-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  margin-bottom: 10px;
  padding: 10px;
}

.any-wrapper {
  display: flex;
  transition: transform 0.5s ease;
}

.any {
  width: 250px;
  height: 300px;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  background-color: #00000022;
  background-blend-mode: darken;
}

.actorname {
  font-size: 1rem;
  font-weight: bold;
  padding-top: 240px;
  padding-left: 10px;
  color: var(--300);
  text-shadow: #000000 0px 0px 5px;
}

.images-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 40px;
  padding: 10px;
  width: 600px;
  background-size: contain;
}

.image-slide {
  border-radius: 10px;
}

.reviews-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;
  padding: 10px;
}

.review-card{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 15px;
}

.review-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.review-username {
  font-weight: bold;
  font-size: 1rem;
  color: var(--400);
  text-transform: capitalize;
}

.review-rating {
  display: flex;
  gap: 0.5rem;
}

.star {
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.star.filled {
  background-image: url("https://svgshare.com/i/17X8.svg");
}

.star:not(.filled) {
  background-image: url("https://svgshare.com/i/17We.svg");
}

.arrow {
  font-size: 1.2rem;
  margin-left: 0.5rem;
}

.similar-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0px;
    margin-bottom: 20px;
    padding: 10px;
}

.similar {
    width: 250px;
    height: 300px;
    background-size: cover;
    border-radius: 10px;
    background-color: #ffffff1a;
    background-blend-mode: overlay;
}

.similarname {
    font-size: 1.2rem;
    font-weight: bold;
    padding-top: 240px;
    margin-bottom: 20px;
    padding-left: 10px;
    color: var(--300);
    text-shadow: #000000 0px 0px 5px;
  }

.sechead h3 {
    border-bottom: 2px solid var(--500);
    padding-bottom: 10px;
    margin-bottom: -5px;
    display: inline-block; /* Add this line */
}