.header {
    display: inline-block;
    border-left: 3px solid var(--500);
    padding-left: 10px;
}

.heading {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-bottom: 150px;
}

input {
    width: 100%;
    padding: 10px;
    font-size: 1.2rem;
    border-radius: 15px;
    border: 3px solid var(--400);
    background-color: transparent;
    color: #fff;
    padding: 10px;
    padding-left: 15px;

}

/* body {
    margin: auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 100px;
} */

.searchpage-input {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    width: 80%;
}
.search-results {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.results-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.movie-card,
.person-card,
.tv-card {
  width: 200px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.card-image {
  width: 100%;
  height: 300px;
  background-size: cover;
}

.card-title {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 10px;
  color: white;
  text-shadow: 2px 2px 4px black;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}
